<template>
  <v-dialog :value="value" max-width="610" persistent>
    <v-snackbar
      top
      v-model="missingLinkedItemsAlert"
      content-class="pa-0"
      color="warning"
      :timeout="-1">
      <v-alert
        v-model="missingLinkedItemsAlert"
        dense
        class="my-0"
        border="left"
        prominent
        type="warning"
        :retain-focus="true">
        {{ missingLinkedItemsMessage }}
        <br/>
        Submit promotion with missing linked items?
        <span>
          <v-btn
            outlined
            @click="submitWithMissingLinkedItems"
            :loading="saving"
            class="mx-4">
            Yes
          </v-btn>
          <v-btn @click="missingLinkedItemsAlert = false">
            No
          </v-btn>
        </span>
      </v-alert>
    </v-snackbar>
    <v-card>
      <v-card-title>Update Promotion Status 
      <v-spacer />
      <v-btn @click="$emit('close')" text color="grey darken-2">
        Cancel
      </v-btn>
      <v-btn
        :loading="saving"
        :disabled="changesDisabled"
        color="primary"
        @click="saveStatus">
        Save
      </v-btn>
      </v-card-title>
      <v-card-text class="mb-0 pb-0 px-4">
        <v-container class="mt-0 pt-0">
          <v-row>
            <v-col v-if="promoStatus">
              <v-select
                v-model="promoStatus.status"
                label="Status"
                :menu-props="{ offsetY: true }"
                :items="validStatuses"
                item-text="name"
                item-color="action"
                return-object
                hide-details="auto"
                :disabled="changesDisabled"
                outlined
                dense>
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea
                v-model="promoStatus.notes"
                label="Notes"
                :disabled="changesDisabled"
                outlined
                dense
                hide-details>
              </v-textarea>
            </v-col>
          </v-row>
          <StatusHistory :promo="promo" />
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import StatusHistory from "@/components/statusHistory.vue"
import WorkflowStatus from "@/axios/status-endpoints"
import Promos from '@/axios/promotion-endpoint'
import { displayAlert } from "@/mixins/alert"
import { utils } from '@/mixins/utils'
export default {
  data() {
    return {
      saving: false,
      missingLinkedItemsAlert: false,
      missingLinkedItemsMessage: '',
      skipLinkedItemsCheck: false,
      promoStatus: null,
      promoHistories: {},
      workflowStatuses: [],
      validStatuses: []
    }
  },
  name: "StatusModal",
  mixins: [displayAlert, utils],
  props: {
    value: Boolean,
    promo: Object,
    vendorIds: {
      type: Array,
      default: () => ([])
    },
    validLumpSums: Boolean,
    validSubvendorSelected: Boolean,
    validCategoryManagerSelected: Boolean,
    lumpSumTotal: Number,
    isPromoStatusAdmin: Boolean,
    readOnlyPromo: Boolean
  },
  components: { StatusHistory },
  created () {
    if (this.promo?.status?.status) {
      this.promoStatus = JSON.parse(JSON.stringify(this.promo.status))
      this.validStatuses.push({ ...this.promoStatus.status })
    }
    this.initWorkflowStatuses()
  },
  computed: {
    invalidTotalErrorMessage () {
      if (['pricechopper', 'alliance-retail-group'].includes(this.$auth.tenant)){
          const message = `The ad fee on this promotion is $${this.promo.lump_sum_allowance} but the ad group ad fee total is $${this.lumpSumTotal.toFixed(2)}.
          Please assign the remaining $${this.lumpSumDiff} to continue.`
          return message
        } else {
          const message = `The lump sum allowance on this promotion is $${this.promo.lump_sum_allowance} but the ad group lump sum total is $${this.lumpSumTotal.toFixed(2)}.
          Please assign the remaining $${this.lumpSumDiff} to continue.`
          return message
        }
    },
    currentStatus () {
      if (this.workflowStatuses.length > 0) {
        return this.workflowStatuses.find(status => {
          return status.current_status.id === this.promo.status.status.id
        })
      }
      return null
    },
    nextStatuses () {
      const nextValues = this.currentStatus?.next_statuses || []
      if (!this.isPromoStatusAdmin) {
        return nextValues.filter(s => s?.constant !== 'APPROVED' && s?.constant !== 'REJECTED' )
      }
      return nextValues
    },
    isApproved () {
      return this.currentStatus?.current_status?.constant === 'APPROVED'
    },
    autoApprove () {
      const categoryName = this.promo?.promo_category?.name.toLowerCase()
      return this.$auth.tenant === 'pricechopper' && (categoryName.includes('edlc') || categoryName.includes('tpr')) && this.promoStatus.status?.constant === 'SUBMITTED'
    },
    changesDisabled () {
      return this.saving
        || (this.nextStatuses.length === 0)
        || (this.isApproved && !this.isPromoStatusAdmin)
    },
    promoLumpSum () {
      return this.getNumericPrice(this.promo.lump_sum_allowance)
    },
    checkLumpSums () {
      if (this.$auth.tenant == 'pricechopper') {
        return (this.promoLumpSum > 0) && ['APPROVED'].includes(this.promoStatus?.status?.constant)
      }
      return (this.promoLumpSum > 0) && ['SUBMITTED', 'APPROVED'].includes(this.promoStatus?.status?.constant)
    },
    lumpSumDiff () {
      const diff = (this.promoLumpSum - this.lumpSumTotal)
      return diff.toFixed(2)
    },
    checkSubvendors () {
      if (['alliance-retail-group','pricechopper'].includes(this.$auth.tenant)) {
        return ['SUBMITTED', 'APPROVED'].includes(this.promoStatus?.status?.constant) && (!['AMAP', 'Early Buy Allowance'].includes(this.promo.promo_category.name))
      }
      return false
    },
    checkCategoryManagers () {
      if (this.$auth.tenant === 'pricechopper') {
        return ['SUBMITTED', 'APPROVED'].includes(this.promoStatus?.status?.constant)
      }
      return false
    },
    checkLinkedItems () {
      return this.$auth.tenant === 'pricechopper' && this.promoStatus.status.constant === 'SUBMITTED' && !this.skipLinkedItemsCheck
    }
  },
  methods: {
    async initWorkflowStatuses () {
      const workflowId = this.promo.status.workflow_id
      try {
        const res = await WorkflowStatus.getWorkflowValues(workflowId)
        this.workflowStatuses = res?.data || []
        if (this.nextStatuses.length > 0) {
          this.validStatuses = [...this.validStatuses, ...this.nextStatuses].sort(this.sortByDisplayOrder)
        }
      } catch (err) {
        this.handleError(err)
      }
    },
    async saveStatus () {
      if (this.checkLumpSums && !this.validLumpSums) {
        const message = this.invalidTotalErrorMessage
          return this.emitAlert(true, 'warning', message)
      }
      if (this.checkSubvendors && !this.validSubvendorSelected) {
        const message = 'At least one subvendor must be selected before the status can be changed.'
        return this.emitAlert(true, 'warning', message)
      }
      if (this.checkCategoryManagers && !this.validCategoryManagerSelected) {
        const message = 'At least one category manager must be selected before the status can be changed to "Submitted" or "Approved".'
        return this.emitAlert(true, 'warning', message)
      }
      if (this.checkLinkedItems) {
        this.saving = true
        const { data } = await Promos.checkLinkedItems(this.promo.id)
        const filteredItems = this.vendorIds.length > 0 ? data.filter(item => { return this.vendorIds.includes(item?.vendor_id) }) : data
        if (filteredItems.length > 0) {
          const incompleteGroups = [...new Set(filteredItems.map(item => item.pce_link_code))]
          const missingItems = filteredItems.map(item => item.item_id)
          const message = 'At least one linked item is missing. Incomplete Linked Group(s): ' + incompleteGroups.join(", ") + '. Missing Item ID(s): ' + missingItems.join(", ")
          this.missingLinkedItemsMessage = message
          this.missingLinkedItemsAlert = true
          this.saving = false
          return
        }
      }
      this.saving = true
      try {
        const res = await WorkflowStatus.add(this.promoStatus)
        if (res?.data?.id && !this.autoApprove) {
          this.emitAlert(true, "success", "Promotion status updated successfully", [], true)
          this.$emit("saved")
        }
        if (this.autoApprove) {
          this.promoStatus.status = this.workflowStatuses.find(status => status.current_status.constant === 'APPROVED').current_status
          const approvedRes = await WorkflowStatus.add(this.promoStatus)
          if(approvedRes?.data?.id) {
            this.emitAlert(true, "success", "Promotion status updated successfully", [], true)
            this.$emit("saved")
          }
        }
      } catch (err) {
        this.handleError(err)
      } finally {
        this.saving = false
      }
    },
    sortByDisplayOrder (a, b) {
      return a.display_order - b.display_order
    },
    submitWithMissingLinkedItems () {
      this.missingLinkedItemsAlert = false
      this.skipLinkedItemsCheck = true
      this.saveStatus()
    }
  }
}
</script>
